import { useCallback, useState } from 'react';
import { Checkbox, HorizontalGrid, HorizontalStack, Link, Modal, VerticalStack, Badge, Button } from '../../../../imb-react';
import { Order } from '../../../../types';
import { getQuoteExpirationDateFormatted, getQuoteExpirationDate } from '../../../../utils/Common';

import styles from './QuoteModal.module.scss';

type QuoteModalType = {
  /** The product id that should be displayed */
  quote: Order;
  /** The modal status */
  active: boolean;
  /** Handler of the modal closing */
  onClose: () => void;
};

export function QuoteModal({ active, onClose, quote }: QuoteModalType) {
  console.log(quote);
  // Check if the quote is empty
  if (!quote.line_items) {
    return null;
  }

  /**
   * Documents state
   */
  const [checkedDocuments, setCheckedDocuments] = useState({
    informationPackage: false,
    dip: false,
    precontractual: false,
    privacy: false,
    brokerage_mandate: false,
  });
  const [checkedDocumentsError, setCheckedDocumentsError] = useState(false);

  // Handle documents checkbox change
  const handleDocumentsCheckboxChange = (checked: boolean, id: string) => {
    setCheckedDocuments((checkedDocuments) => ({ ...checkedDocuments, [id]: !checked }));
  };

  /**
   * Handle checkout redirect
   */
  const handleCheckoutRedirect = useCallback(() => {
    // Check if REACT_APP_CHECKOUT_URL is set
    if (!process.env.REACT_APP_CHECKOUT_URL) {
      return;
    }

    // Check if chekedDocuments are set to false
    if (!checkedDocuments.informationPackage || !checkedDocuments.dip || !checkedDocuments.precontractual || !checkedDocuments.privacy) {
      setCheckedDocumentsError(true);
      return;
    }

    const bank_transfer = quote.line_items[0].bank_transfer;
    if (bank_transfer !== undefined && bank_transfer === true) {
      alert('Questo preventivo prevede il pagamento tramite bonifico bancario. Controlla la tua email per le istruzioni di pagamento.');
    } else {
      // Open link in a new page
      window.open(`${process.env.REACT_APP_CHECKOUT_URL}/?orderId=${quote._id}`, '_blank');
    }
  }, [quote, checkedDocuments]);

  /**
   * Handle temporary checkout
   */
  //const handleCheckoutRedirect = useCallback(() => {
  //  // Check if chekedDocuments are set to false
  //  if (!checkedDocuments.informationPackage || !checkedDocuments.dip || !checkedDocuments.precontractual || !checkedDocuments.privacy) {
  //    setCheckedDocumentsError(true);
  //    return;
  //  }
  //  // Open alert and tell the customer to check the initial email for the instructions
  //  alert('Controlla la tua email per le istruzioni di pagamento');
  //}, [quote, checkedDocuments]);

  /**
   * Render the document link
   * @param {string} document
   */
  const renderDocumentLink = (document: string) => {
    return (
      <Link url={document} imbDarkLow>
        {document}
      </Link>
    );
  };

  /**
   * Render quote expiration markup
   * @param {Date} date_created Data di creazione del preventivo
   * @returns {string} Stato del preventivo: 'Da rinnovare', 'in scadenza', 'attiva'
   */
  const renderQuoteExpirationMarkup = (date_created: Date): string => {
    // Calcola la data di scadenza del preventivo (7 giorni dopo la data di creazione)
    const expirationDate = new Date(date_created);
    expirationDate.setDate(expirationDate.getDate() + 7);

    // Ottieni la data corrente
    const currentDate = new Date();

    // Verifica se il preventivo è attivo, in scadenza o da rinnovare
    if (currentDate < expirationDate) {
      // Il preventivo è attivo perché non sono ancora trascorsi i 7 giorni dalla creazione
      return 'Attiva';
    } else {
      // Il preventivo è scaduto (da rinnovare)
      return 'Da rinnovare';
    }
  };

  // Determina lo stato del preventivo in base alla data di creazione
  // Determina lo stato della polizza in base alla data di scadenza
  const quoteExpirationDate = (date_end: Date): string => {
    const quoteStatus = renderQuoteExpirationMarkup(date_end);
    let badgeColorClass;

    switch (quoteStatus) {
      case 'Da rinnovare':
        badgeColorClass = styles.redBadge;
        break;
      default:
        badgeColorClass = styles.greenBadge;
    }

    return badgeColorClass;
  };

  return (
    <Modal
      large
      open={active}
      onClose={onClose}
      title={'Dettaglio preventivo'}
      titleIconSource={''}
      primaryActionBold
      buttonSizeLarge
      primaryAction={{
        content: 'Acquista',
        onAction: handleCheckoutRedirect,
      }}
      //secondaryActions={[
      //  {
      //    content: 'Codice Amico',
      //    onAction: handleCheckoutRedirect,
      //  },
      //]}
      footer={
        checkedDocumentsError && (
          <div className={styles.QuoteModal__error}>
            <p>Devi accettare tutti i documenti</p>
          </div>
        )
      }
    >
      <Modal.Section>
        <VerticalStack gap="5">
          <HorizontalStack align="space-between" blockAlign="center">
            <div className={styles.QuoteModal__header}>
              <img
                className={styles.QuoteModal__icon}
                src={quote.line_items[0].product.icon && process.env.REACT_APP_BLOB_IMAGES_URL + quote.line_items[0].product.icon.key}
                alt={quote.line_items[0].product.icon && quote.line_items[0].product.icon.title}
              />
              <div className={styles.QuoteModal__title}>{quote.line_items[0].name}</div>
            </div>

            <div className={`${styles.QuoteModalExp} ${quote.date_created && quoteExpirationDate(new Date(quote.date_created))}`}>
              {quote.date_created && quoteExpirationDate(new Date(quote.date_created)) === styles.redBadge ? (
                <>
                  <div className={styles.QuoteModalExp__text}>Il preventivo è scaduto il:</div>
                  <div className={styles.QuoteModalExp__date}>{getQuoteExpirationDate(new Date(quote.date_created))}</div>
                </>
              ) : (
                <>
                  <div className={styles.QuoteModalExp__text}>Il preventivo scade il:</div>
                  <div className={styles.QuoteModalExp__date}>{getQuoteExpirationDate(new Date(quote.date_created))}</div>
                </>
              )}
            </div>
          </HorizontalStack>
          <div className={styles.QuoteModal__number}>
            Preventivo n° <span>12</span>
          </div>

          {/* Main content */}
          <HorizontalGrid columns={['twoThirds', 'oneThird']} gap="5">
            <VerticalStack gap="5">
              {/* Warranties */}
              <div className={styles.QuoteModalCard}>
                <div className={styles.QuoteModalCardBody}>
                  <VerticalStack gap="5">
                    <HorizontalStack>
                      <div className={styles.QuoteModalCard__title}>
                        <div>
                          <h5>Comprende:</h5>
                        </div>
                        <div className={styles.QuoteModalCard__company}>
                          <img
                            src={process.env.REACT_APP_BLOB_IMAGES_URL + quote.line_items[0].company.logo.key}
                            alt={quote.line_items[0].company.logo.title}
                          />
                        </div>
                      </div>
                    </HorizontalStack>

                    <HorizontalStack align="space-between">
                      <div className={styles.QuoteModalCard__warranty}>Nome garanzia</div>
                      <div className={styles.QuoteModalCard__warranty}>Massimale</div>
                      <div className={styles.QuoteModalCard__warranty__body}>
                        {quote.line_items[0].warranties.map((warranty: { name: string; value: string }, index: number) => (
                          <div className={styles.QuoteModalCard__warranty__box} key={index}>
                            <div className={styles.QuoteModalCard__warranty__name}>{warranty.name}</div>
                            <div className={styles.QuoteModalCard__warranty_amount}>{warranty.value} €</div>
                          </div>
                        ))}
                      </div>
                    </HorizontalStack>
                  </VerticalStack>
                </div>
              </div>
            </VerticalStack>

            <VerticalStack gap="5">
              {/* Pricing */}
              <div className={styles.QuoteModalPricing}>
                <p>Premio</p>
                <p className={styles.QuoteModalPricing__amount}>{quote.total_price.toFixed(2)} €/anno</p>
              </div>

              {/* Documents */}
              <div className={styles.QuoteModalDocuments}>
                <div className={styles.QuoteModalDocuments__title}>Documenti da visionare</div>
                <div className={styles.QuoteModalDocuments__list}>
                  <Checkbox
                    checked={checkedDocuments.informationPackage}
                    onChange={() => handleDocumentsCheckboxChange(checkedDocuments.informationPackage, 'informationPackage')}
                    imbGreenBorder
                    label={renderDocumentLink('Fascicolo informativo')}
                    error={checkedDocumentsError && !checkedDocuments.informationPackage}
                  />
                  <Checkbox
                    checked={checkedDocuments.dip}
                    onChange={() => handleDocumentsCheckboxChange(checkedDocuments.dip, 'dip')}
                    imbGreenBorder
                    label={renderDocumentLink('DIP')}
                    error={checkedDocumentsError && !checkedDocuments.dip}
                  />
                  <Checkbox
                    checked={checkedDocuments.precontractual}
                    onChange={() => handleDocumentsCheckboxChange(checkedDocuments.precontractual, 'precontractual')}
                    imbGreenBorder
                    label={renderDocumentLink('Informativa precontrattuale')}
                    error={checkedDocumentsError && !checkedDocuments.precontractual}
                  />
                  <Checkbox
                    checked={checkedDocuments.privacy}
                    onChange={() => handleDocumentsCheckboxChange(checkedDocuments.privacy, 'privacy')}
                    imbGreenBorder
                    label={renderDocumentLink('Privacy')}
                    error={checkedDocumentsError && !checkedDocuments.privacy}
                  />
                  {/* <Checkbox
                    checked={checkedDocuments.brokerage_mandate}
                    onChange={() => handleDocumentsCheckboxChange(checkedDocuments.brokerage_mandate, 'brokerage_mandate')}
                    imbGreenBorder
                    label={renderDocumentLink('Mandato di brokeraggio')}
                    error={checkedDocumentsError && !checkedDocuments.brokerage_mandate}
                  /> */}
                </div>
              </div>
            </VerticalStack>
            {/* discount codes */}
            {/* <VerticalStack>
              <div className={styles.QuoteModalDiscountCodes}>
                <div className={styles.QuoteModalDiscountCodes_box}>
                  <p className={styles.QuoteModalDiscountCodes__text}>Codice sconto in uso</p>
                  <div className={styles.QuoteModalCodeinUse__code}>sadsfdsdfs</div>
                </div>
                <div className={styles.QuoteModalDiscountCodes_box}>
                  <p className={styles.QuoteModalDiscountCodes__text}>Ottieni 5€ di sconto</p>
                  <div className={styles.QuoteModalFriendCode__code}>
                    <Button outline>Codice Amico</Button>
                  </div>
                </div>
              </div>
            </VerticalStack> */}
          </HorizontalGrid>
        </VerticalStack>
      </Modal.Section>
    </Modal>
  );
}
